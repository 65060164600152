<template>

  <div>

    <!-- Table Container Card -->
    <b-card>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          sticky-header
          noCollapse
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="order_id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(order_id)="data">
          #{{ data.item.id }}
        </template>

        <!-- 状态 -->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('reward_order_status', data.item.status)}`"
          >
            {{ getCodeLabel('reward_order_status', data.item.status) }}
          </b-badge>
        </template>

        <!-- 备注 -->
        <template #cell(memo)="data">
          <div :id="`memo-${data.item.id}`">
            <feather-icon icon="Edit3Icon"/>
          </div>
          <b-tooltip
              :target="`memo-${data.item.id}`"
              placement="top"
          >
            {{ data.item.memo }}
          </b-tooltip>
        </template>

        <!-- 附件 -->
        <template #cell(attachments)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :id="data.item.attachments"
                             :readonly="true"
          />
        </template>

        <!-- 添加时间       -->
        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time) }}
        </template>

        <!--添加人        -->
        <template #cell(creator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!-- 更新时间       -->
        <template #cell(update_time)="data">
          {{ toTime(data.item.update_time) }}
        </template>

        <!--更新人        -->
        <template #cell(updator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>


      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import brandrewardorderUseList from './brandrewardorderUseDetail'
import brandrewardorderStore from './brandrewardorderStore'
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import {getUserData} from "@/auth/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
  },
  props: {
    reward_id: {
      type: Number,
      default: 0
    },
  },
  methods: {},

  setup(props) {
    // Register module
    if (!store.hasModule('brandrewardorder')) store.registerModule('brandrewardorder', brandrewardorderStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('brandrewardorder')) store.unregisterModule('brandrewardorder')
    })



    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = brandrewardorderUseList(props)

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,


      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
